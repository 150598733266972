import { createContext, useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CssBaseline,
  Modal,
  Box,
  Typography,
  Button,
  Drawer,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import {
  getPlayerSummary,
  resetSignInResponse,
} from "../redux/slices/user.slice";
import swopApi from "../api";
import {
  saveChannelID,
  getChannelID,
  saveToken,
  resetToken,
  getToken,
  IsSwopStakeWeb,
} from "../services/ls";
import { cleanUrlForGetReq, getHostUrl } from "../utils/common-functions";
import newTheme from "../styles/theme";
import { requestSystemSettings } from "../redux/slices/systemSettings.slice";
import BuyTickets from "../components/menu/BuyTickets";
import { updateBuyDialog } from "../redux/slices/game.slice";
import { getContentCard } from "../redux/slices/gameHome.slice";

export const AppContext = createContext();

let playerSummaryTimer = 0;
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export function AppContextProvider({ children }) {
  const [checkingRemoteLogin, setCheckingRemoteLogin] = useState(false);
  const [loadingSiteConfig, setLoadingSiteConfig] = useState(false);
  const [errorSiteConfig, setErrorSiteConfig] = useState(false);
  const [authData, setAuthData] = useState({});
  const [wSignupData, setWSignupData] = useState();
  const [theme, setTheme] = useState({
    primary: "#093AD4",
    secondary: "#84FEE5",
    background: "#F9F9F9",
    paper: "#FFFFFF",
  });
  const [sessionFinished, setSessionFinished] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const bdOptions = useSelector((state) => state.game.buyDialog);
  const handleBuyDialogClose = () => {
    dispatch(updateBuyDialog(false));
  };

  const spUsername = searchParams.get("username");
  const spAuthToken = searchParams.get("authToken");
  const spHostKey = searchParams.get("signature");
  console.log({ spHostKey, decodedHostkey: decodeURIComponent(spHostKey) });

  const expireCurrentSession = () => {
    setSessionFinished(true);
    resetToken();
    swopApi.tokenUpdated();
  };

  const startPlayerSummaryTimer = (callback) => {
    playerSummaryTimer = setInterval(() => {
      if (swopApi.hasToken()) {
        swopApi.playerApi
          .playerSummaryRequestsummaryGet({
            accept: "application/json",
          })
          .then((r) => {
            console.log("Summary interval response", r);
            if (!r.playerId) {
              console.log("Error player id", r);
              callback();
            }
          })
          .catch((e) => {
            console.log("Error player summary", e);
            callback();
          });
      }
    }, 1000 * 30);
  };

  useEffect(() => {
    return () => {
      clearInterval(playerSummaryTimer);
    };
  }, []);

  useEffect(() => {
    if (!spUsername || !spAuthToken) return;
    setAuthData({ username: spUsername, authToken: spAuthToken });
  }, [spUsername, spAuthToken]);

  const getSystemStatus = async () => {
    try {
      const res = await swopApi.systemStatusApi.killSwitchRequestGet({
        accept: "application/json",
      });
      if (res && res.url) {
        if (res.url.startsWith("http")) window.location.href = res.url;
        else navigate(res.url);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down("sm"));

  const startAutoLoginSignup = async () => {
    if (!IsSwopStakeWeb()) {
      resetToken();
    }
    // Auth errorcode meaning
    // 1: Remote unauthorising attempt
    // 2: Request signup
    const username = authData.username || searchParams.get("username");
    const authToken = authData.authToken || searchParams.get("authToken");
    if (!username || !authToken) {
      console.log("No username/authtoken. skipping...");
      return;
    }
    if (checkingRemoteLogin) {
      console.log("Already checking remote login");
      return;
    }
    console.log("Username/authtoken provided. checking remote login");
    setCheckingRemoteLogin(true);
    try {
      const authResponse = await swopApi.authApi.authenticateproviderPost({
        accept: "application/json",
        provider: "BMToken",
        body: {
          userName: username,
          password: authToken,
          meta: {
            ChannelId: getChannelID(),
          },
        },
      });
      if (authResponse && authResponse.sessionId) {
        saveToken(authResponse.sessionId);
        swopApi.tokenUpdated();
        dispatch(getPlayerSummary());

        // Start polling of player summary to be able to detect session timeout
        startPlayerSummaryTimer(expireCurrentSession);
      }
    } catch (e) {
      console.log(e);
      if (e.status === 401) {
        const authResponse = await e.json();
        if (!authResponse) return;
        if (
          authResponse.ResponseStatus &&
          authResponse.ResponseStatus.ErrorCode === "1"
        ) {
          // remote rejecting it so nothing can be done
          return;
        }
        if (
          authResponse.ResponseStatus &&
          authResponse.ResponseStatus.ErrorCode === "2"
        ) {
          // go to signup
          setWSignupData({ username, authToken });
          navigate("/wsignup");
        }
      }
    } finally {
      setCheckingRemoteLogin(false);
    }
  };

  const startSessionCheckForWeb = () => {
    if (!IsSwopStakeWeb()) {
      return;
    }

    startPlayerSummaryTimer(() => {
      dispatch(resetSignInResponse());
      resetToken();
      swopApi.tokenUpdated();
      navigate("/login", { state: { from: window.location.pathname } });
    });
  };

  // const refreshSession = () => {};

  const getConfig = async () => {
    try {
      setLoadingSiteConfig(true);
      let res;
      if (
        getHostUrl().endsWith("swopstakes.com") ||
        getHostUrl().endsWith("localhost")
      ) {
        res = await swopApi.configApi.configRequestPost({
          accept: "application/json",
          host: cleanUrlForGetReq(getHostUrl()),
        });
      } else {
        res = await swopApi.configApi.configRequestPost({
          accept: "application/json",
          body: {
            hostKey: decodeURIComponent(spHostKey),
          },
        });
      }
      if (
        res &&
        res.config &&
        res.config.ChannelId &&
        res.config.ChannelId > 0
      ) {
        console.log("Set Channel", res.config.ChannelId, getHostUrl());
        saveChannelID(res.config.ChannelId);
        swopApi.tokenUpdated();
      } else {
        console.log(
          "No Channel found, please check encrypted hostKey is valid"
        );
        saveChannelID(0);
        setErrorSiteConfig(true);
      }
      if (res && res.config && res.config.ThemeJsonStr) {
        try {
          const validThemeJson = JSON.parse(res.config.ThemeJsonStr);
          setTheme(() => validThemeJson);
        } catch (e) {
          console.error("Invalid json", e);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingSiteConfig(false);
    }
  };

  const getPlayerDetail = () => {
    dispatch(getPlayerSummary());
  };

  const getPromotions = () => {
    dispatch(getContentCard());
  };

  const getSystemSettings = () => {
    // dispatch(systemSettings());
    dispatch(requestSystemSettings());
  };
  const runInitialSetupSequentially = async () => {
    await getConfig();
    await getSystemStatus();
    await startAutoLoginSignup();
    getSystemSettings();
    if (getToken()) {
      getPlayerDetail();
    }
    getPromotions();
    startSessionCheckForWeb();
  };
  useEffect(() => {
    runInitialSetupSequentially();
  }, []);

  const value = useMemo(() => ({ wSignupData }), [wSignupData]);

  const handleSessionFinishedModalClose = () => {};
  const handleSessionRefresh = async () => {
    startAutoLoginSignup();
    setSessionFinished(false);
  };

  return (
    <AppContext.Provider value={value}>
      <ThemeProvider theme={newTheme(theme)}>
        <CssBaseline />
        <Drawer
          anchor={isMobile ? "bottom" : "right"}
          open={bdOptions.open}
          onClose={handleBuyDialogClose}
          // ModalProps={{ disableScrollLock: true }}
          sx={{
            "& .MuiDrawer-paper": {
              height: "100%",
              bgcolor: "background.default",
              zIndex: "1251",
              ...(isMobile ? {} : { width: "460px" }),
            },
          }}
        >
          <BuyTickets
            defaultTab={bdOptions.defaultTab}
            onClose={handleBuyDialogClose}
            // ModalProps={{ disableScrollLock: true }}
          />
        </Drawer>
        <Modal
          open={sessionFinished}
          onClose={handleSessionFinishedModalClose}
          aria-labelledby="session-finished"
          aria-describedby="session-finished"
        >
          <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Session expired
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Your session has expired. Please click the button below to restart
              your session.
            </Typography>
            <Button variant="contained" onClick={handleSessionRefresh}>
              Okay
            </Button>
          </Box>
        </Modal>
        {checkingRemoteLogin ? (
          <p>Checking remote login</p>
        ) : loadingSiteConfig ? (
          <p>Loading site config</p>
        ) : errorSiteConfig ? (
          <p>No Channel found, please check encrypted hostKey is valid</p>
        ) : (
          children
        )}
      </ThemeProvider>
    </AppContext.Provider>
  );
}

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default null;
